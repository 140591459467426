<template>
	<a-space direction="vertical">
		<a-card title="管理员列表">
			<a-space slot="extra"><a-button type="primary" @click="onCreate">新增</a-button></a-space>
			<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="userId" title="ID">
					<template slot-scope="text, record">
						{{ record.userId }}
					</template>
				</a-table-column>
				<a-table-column key="userName" title="姓名">
					<template slot-scope="text, record">
						{{ record.userName }}
					</template>
				</a-table-column>
				<a-table-column key="accountNum" title="账号" data-index="accountNum" />
				<a-table-column key="loginDate" title="最新登录时间" data-index="loginDate" />
				<a-table-column key="loginIp" title="登录IP" data-index="loginIp" />
				<a-table-column key="enabledStr" title="账号状态" data-index="enabledStr">
					<template slot-scope="text, record">
						{{ record.enabledStr }}
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" @click="onEdit(record)">修改</a-button>
							<a-button size="small" @click="onViewAuth(record)">权限</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>


		<a-modal v-drag-modal :title="modal.type=='create'?'新增':`修改`" v-model="modal.visible" :bodyStyle="{ padding: '10px' }">
			<a-form-model ref="form" :model="modal.formdata" :rules="modal.rules" v-bind="layout" autocomplete="off">
				<a-form-model-item label="姓名" prop="userName">
					<a-input v-model="modal.formdata.userName" placeholder="请输入姓名" />
				</a-form-model-item>
				<a-form-model-item label="登录账号" prop="accountNum">
					<a-input v-model="modal.formdata.accountNum" placeholder="请输入账号(手机号码)" />
				</a-form-model-item>
				<a-form-model-item label="登录密码" prop="password" v-if="modal.type=='create'">
					<a-input-password v-model="modal.formdata.password" placeholder="请输入登录密码" />
				</a-form-model-item>
				<a-form-model-item label="登录密码" prop="npassword" v-else-if="modal.type=='edit'">
					<a-input-password v-model="modal.formdata.npassword" placeholder="请输入登录密码" />
				</a-form-model-item>
				<a-form-model-item label="状态" prop="enabled" v-if="modal.type=='edit'">
					<a-switch v-model="modal.formdata.enabled">
						<a-icon slot="checkedChildren" type="check" />
						<a-icon slot="unCheckedChildren" type="close" />
					</a-switch>
				</a-form-model-item>
			</a-form-model>
			<div slot="footer">
				<a-button @click="modal.visible = false">取消</a-button>
				<a-button type="primary" :loading="modal.loading" @click="onSave">保存</a-button>
			</div>
		</a-modal>

		<a-modal v-drag-modal title="账号权限" v-model="modalAuthorize.visible" :bodyStyle="{ padding: '10px' }" :width="800">
			<a-form-model ref="formStock" :model="modalAuthorize.formdata" :rules="modalAuthorize.rules" v-bind="layout">
				<a-form-model-item label="菜单权限" prop="idStr">
					<a-tree v-model="modalAuthorize.formdata.checkedKeys" checkable :expanded-keys.sync="modalAuthorize.expandedKeys" :auto-expand-parent="true" :tree-data="modalAuthorize.authorizes" :replaceFields="{children:'child', title:'httpPathName', key:'id' }">
						<span slot="title" slot-scope="{ httpPathName, httpPath }"> {{ httpPathName }} ({{ httpPath }}) </span>
					</a-tree>
				</a-form-model-item>
			</a-form-model>
			<div slot="footer">
				<a-button @click="modalAuthorize.visible = false">取消</a-button>
				<a-button type="primary" :loading="modalAuthorize.loading" @click="onSaveAuthorize">保存</a-button>
			</div>
		</a-modal>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import { ROLES } from '@/utils/constants.js';
	import { copy, parseQuery, numberFormat } from '@/utils';
	const formatter = 'YYYY-MM-DD';

	const DEFAULT_QUERY = {
		page: 1,
		size: 10,
		invCode: undefined,
		mobile: undefined,
		beginTime: undefined,
		endTime: undefined,
		beginUpgradeTime: undefined,
		endUpgradeTime: undefined,
		roleId: undefined,
		uid: undefined,
		superior: undefined,
		orderByType: undefined,
		money: undefined,
		goodsNum: undefined,
		productId: undefined
	};

	export default {
		data() {
			return {
				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 18
					}
				},
				products: [],
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				},
				ROLES,
				modal: {
					type: 'create',
					loading: false,
					visible: false,
					formdata: { id: '', accountNum: '', userName: '', password: '', npassword: '', enabled: 1 },
					rules: {
						userName: { required: true, message: '请输入姓名' },
						accountNum: { required: true, message: '请输入登录账号' },
						password: { required: true, message: '请输入登录密码' }
					}
				},

				modalAuthorize: {
					loading: false,
					visible: false,
					menus: [],
					authorizes: [],
					expandedKeys: [],
					formdata: { checkedKeys: [], userId: '' }
				},

			};
		},

		async mounted() {
			const response = await this.$api.get('/user_query_product/1');
			this.products = (response && response.data) || [];
			Object.assign(this.query, this.$route.query);
			this.getAdminUsers();

		},
		filters: {
			numberFormat
		},
		methods: {
			async getAdminUsers() {
				const response = await this.$api.get('/admin_user_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					response.data.total = parseInt(response.data.total);
					this.lists = Object.assign(this.lists, response.data);
					location.hash = this.$route.path + '?' + parseQuery(this.query);
				}
			},
			async onPageChange(pagination, filters, sorter) {
				this.query.page = pagination.current;
				this.query.size = pagination.pageSize;
				this.getAdminUsers();
			},
			async onSearch() {
				this.query.page = 1;
				this.getAdminUsers();
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY);
				this.getAdminUsers();
			},
			async onCreate() {
				this.modal.type = 'create'
				this.modal.formdata = { accountNum: '', userName: '', password: '' }
				this.modal.loading = false
				this.modal.visible = true
			},
			async onEdit(record) {
				this.modal.type = 'edit'
				this.modal.formdata = { id: record.userId, accountNum: record.accountNum, userName: record.userName, password: '', npassword: '', enabled: record.enabled == 1 }
				this.modal.loading = false
				this.modal.visible = true
			},
			async onViewAuth(record) {
				const response = await this.$api.get('/admin_route_list', {
					params: { userId: record.userId }
				});
				if (response.code == 200) {
					this.modalAuthorize.formdata.checkedKeys = []
					response.data.forEach(item => {
						(item.child || []).forEach(vitem => {
							if (vitem.label == 1) {
								this.modalAuthorize.formdata.checkedKeys.push(vitem.id)
							}
						})
					})
					this.modalAuthorize.formdata.userId = record.userId
					this.modalAuthorize.authorizes = response.data
					this.modalAuthorize.expandedKeys  = []
					this.modalAuthorize.visible = true
				}
			},
			async onSave() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						this.modal.loading = true
						const { id, accountNum, userName, password, npassword, enabled } = copy(this.modal.formdata);
						let response
						if (this.modal.type == 'create') {
							response = await this.$api.post('/account_insert', { accountNum, userName, password }).catch(e => {
								this.modal.loading = false
							})
						} else {
							response = await this.$api.post('/password_update', { id, accountNum, userName, password: npassword || undefined, enabled: enabled ? 1 : 0 }).catch(e => {
								this.modal.loading = false
							})
						}
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getAdminUsers();
							this.modal.visible = false
						}
						this.modal.loading = false
					}
				});
			},
			async onSaveAuthorize() {
				const { userId, checkedKeys } = this.modalAuthorize.formdata
				const ids = checkedKeys.slice(0)
				this.modalAuthorize.authorizes.forEach(item => {
					let sids = (item.child || []).map(v => v.id)
					if (checkedKeys.some(v => sids.includes(v)) && !checkedKeys.includes(item.id)) {
						ids.push(item.id)
					}
				})
				this.modalAuthorize.loading = true
				const response = await this.$api.post('/admin_route_insert', { userId: userId, idStr: ids.join(',') }).catch(e => {
					this.modalAuthorize.loading = false
				})
				if (response && response.code == 200) {
					this.$message.success('操作成功');
					this.getAdminUsers();
					this.modalAuthorize.visible = false
					this.modalAuthorize.loading = false
				}
			}
		}
	};
</script>